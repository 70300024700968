body {
  text-align: center;
  color: #293c4b;
  margin: 0;
  font-family: Source Sans Pro, Trebuchet MS, Lucida Grande, Bitstream Vera Sans, Helvetica Neue, sans-serif;
}

h1 {
  font-size: 30px;
}

img {
  max-width: 200px;
  margin: 20px 0;
}

/*# sourceMappingURL=index.6670044c.css.map */
